<template>
    <v-card class="mt-4">
        <v-toolbar>
            <div>
                <!-- :input="companyId" -->
                <!-- <form-element labelClass="ml-8" class="mb-1 mr-6"> -->
                <v-select
                    dense
                    flat
                    hide-details
                    return-object
                    prepend-icon="mdi-office-building"
                    placeholder="Select company"
                    class="mt-2"
                    :items="companies"
                    item-text="name"
                    item-value="id"
                    @change="selectCompany"
                ></v-select>
                <!-- </form-element> -->
            </div>
            <!-- <form-element labelClass="ml-8" class="mb-1">
            <v-select
                dense
                prepend-icon="mdi-calendar-clock"
                placeholder="Choose date range"
                class="mt-2"
                solo
                :items="ranges"
                v-model="range"
            ></v-select>
            </form-element>-->
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" persistent max-width="1050" scrollable>
                <template v-slot:activator="{on}">
                    <v-btn
                        color="primary"
                        :fab="$vuetify.breakpoint.mobile"
                        v-on="on"
                        :disabled="!company"
                        title="Upload Files"
                    >
                        <v-icon>mdi-file-upload</v-icon>
                        <span v-if="$vuetify.breakpoint.smAndUp" class="ml-3">Upload Files</span>
                    </v-btn>
                </template>
                <application-uploads-upload @close="dialog=false" :company="company"></application-uploads-upload>
            </v-dialog>
        </v-toolbar>
    </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import { VCard } from "vuetify";

export default {
    name: "application-uploads-toolbar",
    components: {
        VCard,
        ApplicationUploadsUpload: () => import("./ApplicationUploadsUpload"),
    },
    props: {
        // companyId: {
        //     type: String,
        //     required: false,
        //     default: "",
        // },
    },
    data() {
        return {
            range: 1,
            dialog: false,
            company: null,
            ranges: [
                {
                    text: "Last 7 days",
                    value: 1,
                },
                {
                    text: "Yesterday",
                    value: 2,
                },
                {
                    text: "Last 30 days",
                    value: 3,
                },
                {
                    text: "Last 60 days",
                    value: 4,
                },
                {
                    text: "Last 90 days",
                    value: 4,
                },
            ],
        };
    },
    computed: {
        ...mapGetters("Company", ["companies"]),
    },
    watch: {},
    created() {},
    mounted() {
        this.$store.dispatch("Company/getCompanies");
    },
    updated() {},
    detroyed() {},
    methods: {
        selectCompany(company) {
            this.company = company;
        },
        selectRange(range) {
            this.range = range;
        },
    },
};
</script>

<style scoped>
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-4" },
    [
      _c(
        "v-toolbar",
        [
          _c(
            "div",
            [
              _c("v-select", {
                staticClass: "mt-2",
                attrs: {
                  dense: "",
                  flat: "",
                  "hide-details": "",
                  "return-object": "",
                  "prepend-icon": "mdi-office-building",
                  placeholder: "Select company",
                  items: _vm.companies,
                  "item-text": "name",
                  "item-value": "id"
                },
                on: { change: _vm.selectCompany }
              })
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "1050", scrollable: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            attrs: {
                              color: "primary",
                              fab: _vm.$vuetify.breakpoint.mobile,
                              disabled: !_vm.company,
                              title: "Upload Files"
                            }
                          },
                          on
                        ),
                        [
                          _c("v-icon", [_vm._v("mdi-file-upload")]),
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c("span", { staticClass: "ml-3" }, [
                                _vm._v("Upload Files")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c("application-uploads-upload", {
                attrs: { company: _vm.company },
                on: {
                  close: function($event) {
                    _vm.dialog = false
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }